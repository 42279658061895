import {Link, StaticQuery, graphql} from 'gatsby';
import React from 'react';

class NavigationLinks extends React.Component {

  constructor(props) {
    super(props);
    this.state = {selectedSection: null};
  }

  toggleSection(evt, section_id) {
    evt.preventDefault();
    const selection = this.state.selectedSection === section_id ? null : section_id;
    this.setState({selectedSection: selection});
  }

  closeMenu(evt) {
    this.setState({selectedSection: null});
  }

  getNodeById(node_id) {
    const edge = this.props.data.allFile.edges.find(edge => {
      return edge.node.name === node_id;
    });
    if (!edge) {
      return null;
    }
    return edge.node.childPagesYaml;
  }

  renderLinkFor(node_id) {
    const lang = this.props.language;
    const node = this.getNodeById(node_id);
    const fields = node ? node[`fields_${lang}`] : {slug: `/${node_id}`, title: node_id};
    return <Link to={fields.slug}>{fields.nav_title || fields.title}</Link>
  }

  render() {
    const lang = this.props.language;

    return (
      <ul>
        <li>
          <button onClick={evt => this.toggleSection(evt, 'galleries')}>
            {lang === 'sl' ? 'Galerije' : 'Galleries'}
          </button>
          <ul data-selected={this.state.selectedSection === 'galleries'} onClick={evt => this.closeMenu(evt)}>
            <li>{this.renderLinkFor('business-portrait')}</li>
            <li>{this.renderLinkFor('lifestyle-portrait')}</li>
            <li>{this.renderLinkFor('beauty-headshot-portrait')}</li>
            <li>{this.renderLinkFor('promo-portrait')}</li>
            <li>{this.renderLinkFor('fashion-object-photography')}</li>

            <li className="new-section">{this.renderLinkFor('family-portrait')}</li>
            <li>{this.renderLinkFor('child-portrait')}</li>

            <li className="new-section">{this.renderLinkFor('creative-portrait')}</li>

            <li className="new-section">{this.renderLinkFor('document-portrait')}</li>
          </ul>
        </li>
        <li>
          <button onClick={(evt) => this.toggleSection(evt, 'info')}>Info</button>
          <ul data-selected={this.state.selectedSection === 'info'}>
            <li>{this.renderLinkFor('about-me')}</li>
            <li>{this.renderLinkFor('gift-certificates')}</li>
            <li>{this.renderLinkFor('faq')}</li>
          </ul>
        </li>
        <li>
          {this.renderLinkFor('price-list')}
        </li>
        <li>
          {this.renderLinkFor('contact')}
        </li>
        <li>
        <a href="https://www.andrazgregoric.com/porocni-fotograf/">
          {'Poročni fotograf'}
        </a>
        </li>
      </ul>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: {relativeDirectory: {eq: "pages"}}) {
          edges {
            node {
              name
              childPagesYaml {
                fields_sl {
                  slug
                  title
                  nav_title
                }
                fields_en {
                  slug
                  title
                  nav_title
                }
              }
            }
          }
        }
      }
    `}
    render={data => <NavigationLinks data={data} {...props} />}
  />
);
