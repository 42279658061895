import classNames from 'classnames';
import {Link, graphql} from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet'

import Layout from './layout';

class SlideshowGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeImageIdx: 0}
  }

  componentDidMount() {
    const maxIdx = this.props.images.length - 1;
    this.interval = setInterval(() => {
      const currentIdx = this.state.activeImageIdx;
      const newIdx = currentIdx >= maxIdx ? 0 : currentIdx + 1;
      this.setState({activeImageIdx: newIdx});
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  selectImage(idx) {
    clearInterval(this.interval);
    this.setState({activeImageIdx: idx});
  }

  render() {
    const lang = this.props.language;
    return (
      <div className='slideshow-gallery'>
        {this.props.images.map((image, idx) => {
          const title = image[`title_${lang}`] || image.title_sl || '';
          const mobile_src = image.src.replace('/slideshow/', '/slideshow/mobile/');
          return (
              <img key={image.src}
                   src={image.src}
                   srcSet={`${image.src} 1800w, ${mobile_src} 1000w`}
                   alt={title}
                   title={title}
                   data-active={idx === this.state.activeImageIdx}
              />
          );
        })}
        <div className='slideshow-progress'>
          {this.props.images.map((image, idx) => {
            const title = image[`title_${lang}`] || image.title_sl || '';
            return (
              <button key={image.src}
                      title={title}
                      data-active={idx === this.state.activeImageIdx}
                      onClick={evt => this.selectImage(idx)}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

function ThumbnailGallery({images, language}) {
  return (
    <div className='thumbnail-gallery'>
      {images.map(image => {
        const title = image[`title_${language}`] || image.title_sl || '';
        const href = image[`href_${language}`];
        return (
          <Link to={href} key={image.src}>
            <img src={image.src} alt={title} title={title} />
            <span>{title}</span>
          </Link>
        );
      })}
    </div>
  );
}

function StandardGallery({images, language}) {
  return (
    <div className='standard-gallery'>
      {images.map(image => {
        const title = image[`title_${language}`] || image.title_sl || '';
        const caption = image[`caption_${language}`];
        return (
          <figure key={image.src}>
            <img src={image.src} alt={title} title={title} />
            {caption ? <figcaption>{caption}</figcaption> : null}
          </figure>
        );
      })}
    </div>
  );
}

export default function Page(props) {
  const lang = props.pageContext.language;
  const page_yaml = props.data.pagesYaml;
  const fields = page_yaml[`fields_${lang}`];
  const html = page_yaml.fields[`html_${lang}`];

  let slideshow_gallery = null;
  if (page_yaml.slideshow_gallery && page_yaml.slideshow_gallery.length) {
    slideshow_gallery = <SlideshowGallery images={page_yaml.slideshow_gallery} language={lang} />;
  }

  let thumbnail_gallery = null;
  if (page_yaml.thumbnail_gallery && page_yaml.thumbnail_gallery.length) {
    thumbnail_gallery = <ThumbnailGallery images={page_yaml.thumbnail_gallery} language={lang} />;
  }

  let standard_gallery = null;
  if (page_yaml.standard_gallery && page_yaml.standard_gallery.length) {
    standard_gallery = <StandardGallery images={page_yaml.standard_gallery} language={lang} />;
  }

  return (
    <Layout language={lang} nodeData={page_yaml}>
      <Helmet>
        <title>Andraž Gregorič - {fields.title}</title>
      </Helmet>
      {slideshow_gallery}
      <div className={classNames('main-content', {'short-text': html.replace(/<h1>(.*?)<\/h1>/, '').length < 120})}
           dangerouslySetInnerHTML={{__html: html}}
      />
      {thumbnail_gallery}
      {standard_gallery}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    pagesYaml(id: {eq: $id}) {
      fields {
        html_sl
        html_en
      }
      fields_sl {
        slug
        title
        meta_description
        meta_keywords
      }
      fields_en {
        slug
        title
        meta_description
        meta_keywords
      }
      slideshow_gallery {
        src
        title_sl
        title_en
      }
      standard_gallery {
        src
        title_sl
        title_en
        caption_sl
        caption_en
      }
    }
  }
`;
